<template>
  <div class="csn-mmg">
    <section class="section-menu-buttons">
      <div class="row-fluid">
        <div class="col-md-6 csn-mm-header">
          <a @click="returnToMainMenu">
            <SlideArrow :isBack="true" />
          </a>
        </div>
        <div class="col-md-6 text-right csn-mm-header">
          <a class="close-btn" @click="TOGGLE_MAIN_MENU">
            <CloseIcon />
          </a>
        </div>
      </div>
    </section>

    <div class="main-menu csn-mm-list">
      <ul>
        <li
          v-for="({ name }, slug) in VENDOR_DICTIONARY"
          :key="slug"
          class="csn-mm-list-li"
        >
          <Route
            :to="{ name: gameVendorRoute, params: { slug } }"
            class="csn-mm-list-route csn-mm-list-a"
          >
            {{ name }}
          </Route>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import {
  Module,
  MAIN_MENU_GAME,
  VENDOR_DICTIONARY,
  TOGGLE_MAIN_MENU,
  TOGGLE_MAIN_MENU_COMPONENT,
  UPDATE_VENDOR_DICTIONARY,
  MAIN_MENU,
  RouteName,
} from '@/constants'
import { isEmptyOrNil } from '@/helpers'

export default {
  name: MAIN_MENU_GAME,
  components: {
    Route: () => import('@/components/Route'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.VENDOR, [VENDOR_DICTIONARY]),
    gameVendorRoute() {
      return RouteName.GAME_VENDOR
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MAIN_MENU,
      TOGGLE_MAIN_MENU_COMPONENT,
    ]),
    ...mapActions(Module.VENDOR, [UPDATE_VENDOR_DICTIONARY]),
    returnToMainMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU })
    },
  },
  created() {
    isEmptyOrNil(this.VENDOR_DICTIONARY) && this.UPDATE_VENDOR_DICTIONARY()
  },
}
</script>

<style scoped></style>
